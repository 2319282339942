import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import createSalesAgreement from './createSalesAgreement';
import party from '@/app/components/generalLedger/party';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
export default {
  name: 'SalesAgreement',
  components: {
    DatePicker,
    createSalesAgreement,
    party,
    HistoryRecord,
    ApprovalHistory,
    sendForApproval,
    UploadDocument,
    openKmAuthentication
  },
  watch: {
    currentPage: function() {
      this.getSalesAgreementDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSalesAgreementDetails();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      loader: false,
      isViewMore: false,
      modalTitle: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: null,
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      salesAgreementHdrId: null,
      showCreateSalesAgreementModal: false,
      salesAgreementDetails: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: { text: null, value: null },
      operatingUnit: { text: null, value: null },
      project: { text: null, value: null },
      documentNum: null,
      customerPoNum: null,
      customer: { name: null, id: null },
      customerSite: { name: null, id: null },
      status: { text: null, value: null },
      approvalStatus: { text: null, value: null },
      startDate: null,
      endDate: null,
      defaultValue: { text: null, value: null },
      openkmAuthToken: null,
      showOpenKmModal: false,
      createCopyWorkflow: false,
      salesAgreementData: [],
      salesAgreementFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'document_num',
          label:'Sales Agmt No.'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_name',
          label: 'Customer Site'
        },
        {
          key: 'customer_po_num',
          label: 'Customer PO Num'
        },
        {
          key: 'le_name',
          label: 'LE Name'
        },
        {
          key: 'ou_name',
          label: 'OU Name'
        },
        {
          key: 'fms_prj_name'
        },
        {
          key: 'lease_prj_name'
        }, 
        {
          key: 'is_external'
        },
        {
          key: 'unit_type'
        },
        {
          key: 'unit_name'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'status'
        },
        {
          key: 'version'
        },
        {
          key: 'currency_name'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'approval_status'
        }
      ],
      showCustomerSiteModal: false,
      showPartyModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        }
      ],
      isExternal:null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideCreateSalesAgreementModal(true, 'add');
          this.salesAgreementDetails = null;
          this.showHistoryBtn = false;
        }
        if (actionName === 'download' && !this.showCreateSalesAgreementModal) 
        {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'salesAgreement/getSalesAgreementList',
            'sales-agreement',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getSalesAgreementDetails() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        ou_id: this.operatingUnit.value,
        fms_prj_id: this.project.value,
        document_num: this.documentNum,
        document_po_num: this.customerPoNum,
        customer_id: this.customer.id,
        customer_site_id: this.customerSite.id,
        status: this.status.value,
        approval_status: this.approvalStatus.value,
        start_date: this.startDate,
        end_date: this.endDate,
        is_external: this.isExternal
      };
      this.loader = true;
      this.$store
        .dispatch('salesAgreement/getSalesAgreementList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.salesAgreementData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.project = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.AGREEMENT_STATUS) {
        this.status = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.approvalStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (vsetCode === this.project.value) {
        this.project = this.defaultValue;
      } else if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.customer.id) {
        this.customer = { id: null, name: null };
        this.customerSite = { id: null, name: null };
      } else if (vsetCode === this.customerSite.id) {
        this.customerSite = { id: null, name: null };
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.AGREEMENT_STATUS) {
        this.status = this.defaultValue;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.approvalStatus = this.defaultValue;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    rowSelected(item) {
      this.salesAgreementDetails = Object.assign({}, item);
      this.showCreateSalesAgreementModal = true;
      this.salesAgreementHdrId = item.sales_agreement_hdr_id;
    },
    showHideCreateSalesAgreementModal(flag, modalTitle) {
      this.showCreateSalesAgreementModal = flag;
      this.modalTitle = modalTitle;
    },
    clearFilters() {
      this.salesAgreementData = [];
      this.currentPage = 1;
      this.totalRows = null;
      this.isViewMore = false;
      this.legalEntity = this.defaultValue;
      this.operatingUnit = this.defaultValue;
      this.project = this.defaultValue;
      this.documentNum = null;
      this.customerPoNum = null;
      this.customer = { id: null, name: null };
      this.customerSite = { id: null, name: null };
      this.status = this.defaultValue;
      this.approvalStatus = this.defaultValue;
      this.startDate = null;
      this.endDate = null;
      this.showHistoryBtn = false;
      this.isExternal = null;
    },
    selectedSite(item) {
      this.customerSite.name = item.site_name;
      this.customerSite.id = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
    },
    getSelectedPartyCustomer(item) {
      this.customer.name = item.party_name;
      this.customer.id = item.party_id;
      this.customerSite.name = null;
      this.customerSite.id = null;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.customer.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.customer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customer_id: this.customer.id,
        ou_id: this.operatingUnit.value
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteByOu', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSalesData(item) {
      this.showHistoryBtn = true;
      this.salesAgreementHdrId = item.sales_agreement_hdr_id;
    },
    checkSalesAgreementHdrId() {
      if (!this.salesAgreementHdrId) {
        alert('Please save the record!');
        return false;
      }
      return true;
    },
    showHideHistory(flag, historyType) {
      if (this.checkSalesAgreementHdrId()) {
        this.showHistory = flag;
        this.historyType = historyType;
      }
    },
    checkOpenKmPasswordSaved() {
      if (this.checkSalesAgreementHdrId()) {
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/checkSavedPassword')
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if (response.data.data) {
                this.getOpenKmAuthToken();
              } else {
                this.showHideOpenKmModal(true);
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.salesAgreementDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.salesAgreementDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
  
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
